<template>
  <div class="container">
    <div class="header-container">
      <header>
        <van-nav-bar
            title="结算信息核算"
            left-text="返回"
            right-text=""
            left-arrow
            @click-left="onBack"
        />
      </header>
    </div>
    <div class="section-box">
      <div class="main-top" style="background:#fff">
        <div class="main-top-div1">户号：{{bindInfo.accountNo}}</div>
        <div class="main-top-div2">户名：{{bindInfo.accountName}}</div>
        <div class="main-top-div3">地址：{{bindInfo.address}}</div>
      </div>
    </div>
    <table class="bill-base-detail">
      <tr>
        <th colspan=2> 您本次交费信息如下:</th>
      </tr>
      <tr>
        <td>应交金额</td>
        <td style="text-align:right">{{settlementInfo.应交金额}}元</td>
      </tr>
      <tr>
        <td>预存余额</td>
        <td style="text-align:right">{{settlementInfo.预存余额}}元</td>
      </tr>
      <tr>
        <td>抵扣金额</td>
        <td style="text-align:right">{{settlementInfo.抵扣金额}}元</td>
      </tr>
      <tr>
        <td style="font-weight:600;">实缴金额</td>
        <td style="text-align:right;font-weight:600;">{{settlementInfo.实缴金额}}元</td>
      </tr>
    </table>
    <ul class="message-ul">
	  	<li class="title">温馨提示：</li>
	  	<li>1、交错户号不能退回，请仔细核对交费户号；</li>
	  	<li>2、在您交费后，欠费金额将在24小时内进行核销。</li>
	  	<li> 操作剩余时间:<i id="second" style="color: #ff6060;font-size: 18px;padding-right:5px;">{{timeCount}}</i>秒</li>
	  </ul>
    <van-row gutter="20">
      <van-col span="6" offset="6">
          <van-button type="info" hairline block size="small"  @click="payBill">确定</van-button>
      </van-col>
      <van-col span="6">
          <van-button type="warning" hairline  block size="small" @click="onBack">取消</van-button>
      </van-col>
    </van-row>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>

<script>
import Loading from "../../components/logding";
import {getKey} from "../../utils/cookie";
import {getInfo, checkBillPay,billPay} from "../../api/billSearch";
export default {
  name: "index",
  components:{Loading},
  data(){
    return{
      isShow : true,
      lodingTxt: '加载中...',
      timeCount:60,
      timer:null,
      accountNo: '',
      isLogin: false,
      bindInfo:{},
      settlementInfo:{},
    }
  },
  created() {
    //重新获取登录状态
    const isLogin = (getKey(process.env.VUE_APP_H5_IS_LOGIN) || 'false') == 'true' ? true : false
    this.isLogin = isLogin
    console.log('islogin='+isLogin)
    //if (isLogin){
      this.bindInfo=JSON.parse(localStorage.getItem("selectedAccount"));
      this.billSettlement();
    //}
  },
  methods:{
    onBack : function (){
      if(this.timer){
        clearInterval(this.timer);
        this.timer=null;
      }
      this.$router.go(-1);
    },
    onChange(event){
      console.log(JSON.stringify(event));
      this.indexA=true;
      this.indexB=true;
    },
    billSettlement(){
      console.log("账单结算核算");
      console.log("账单结算查询参数--:"+JSON.stringify(this.$route.query))
      let billNoSet=JSON.stringify(this.$route.query.billNoSet);
      let accountNo=this.bindInfo.accountNo;
      const that = this
      that.isShow=true;
      checkBillPay(accountNo,billNoSet).then(response => {
        //console.log("账单结算核算信息1111111:"+JSON.stringify(response))
        that.settlementInfo = response.data;
        that.checkTimer();
        that.isShow=false;
      }).catch(() => {
        that.isShow=false;
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    payBill(){
      let that = this
      that.isShow=true;
      console.log("账单支付：发起---"+JSON.stringify(this.bindInfo));
      billPay(JSON.stringify(that.bindInfo),that.settlementInfo.selBillArray,that.settlementInfo.实缴金额).then(response => {
        console.log("账单支付：下单回复");
        //console.log("账单支付返回："+JSON.stringify(response));
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          that.isShow=false;
          window.location.href=response.data.mweb_url+'&redirect_url=https%3A%2F%2Fm.km96106.cn%2F';
        }else{
          that.isShow=false;
          that.$toast(response.message)
        }
      }).catch(() => {
        that.isShow=false;
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    checkTimer(){
      const TIME_COUNT=60;
      if(!this.timer){
        this.timeCount=TIME_COUNT;
        this.timer=setInterval(()=>{
          if(this.timeCount>0&&this.timeCount<=TIME_COUNT){
            this.timeCount--;
          }else{
            this.onBack();
          }
        },1000);
      }
    },
    onBindAccount(){}
  }
}
</script>

<style scoped>
.main-top{padding: 0px; border-bottom:1px solid #ebebeb; color: black;}
.main-top-div1{
  height:40px;
  padding:5px 10px 5px 10px;
  color:#323233;
  font-size:100%;
  line-height:40px;
  font-weight:normal;
}

.main-top-div2{
  height:40px;
  padding:0px 10px 5px 10px;
  color:#969799;
  font-size:100%;
  line-height:40px;
  font-weight:normal;
}

.main-top-div3{
  padding:5px 10px 5px 10px;
  color:#969799;
  font-size:100%;
  line-height:20px;
  font-weight:normal;
}
.van-checkbox{
  margin-top:6px;
  margin-bottom:0px;
}

.van-checkbox span{
    width:1000px!import;
}

.van-dropdown-menu__bar--opened {text-align: left;background: #db2828!important; color: #fff}
.van-dropdown-item__option--active{background: #db2828!important; color: #db2828;}

.van-dropdown-menu__title{
  font-size:12px!important;
}
.1van-dropdown-menu{
  background:red;
  height:100px;
}

.1van-dropdown-menu__bar{
  margin-bottom:100px;
  background:green;
  height:120px ! important;
}

.1van-dropdown-menu__item{
  height:120px ! important;
}

.label-class-1{
  width:100%!import;
}

.bill-base-detail {
  width: 100%;
  font-size: 100%;
  color: #515151;
  border:1px solid #fff;
}

.bill-base-detail th {
  height: 40px;
  line-height: 40px;
  text-align: center;
  color:#fff;
  background: #1989fa;
}

.bill-base-detail td {
  padding:5px 10px 5px 10px;
  border-bottom:1px solid #ddd;
  height: 30px;
  line-height: 30px;
  text-align: left;
  background: #fff;
}

.bill-base-detail td input{
  height:18px;
  width:18px;
}
.message-ul{
	margin:10px 2px 20px 2px;
	padding:10px;
	line-height:20px;
	background:#eee;
}
.message-ul li{
	font-size:14px;
}

.message-ul .title{
	font-weight:bold;
  line-height:30px;
}
</style>
</style>
